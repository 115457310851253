.section-one {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  background-color: #fffdfe;
  z-index: auto;
}

.animation svg {
  max-width: 100%;
  max-height: 100%;
}
.section-one_inside {
  display: flex;
  width: 100%;
  margin: 30px 120px;
  justify-content: space-between;
}

.section-one_inside-left {
  display: flex;
  flex-direction: column;
  width: 55%;
  justify-content: center;
}

.section-one_inside-left span {
  font-weight: 400;
  color: #009985;
  font-size: 1.5rem;
}

.section-one_button {
  margin-top: 5rem;
}

.section-one_button span {
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2.5rem;
  color: black;
  text-transform: uppercase;
  padding: 20px 20px 20px 0px;
  border-bottom: 1.5px solid #000;
}

.border-line-button {
  margin-top: 18px;
  width: 50%;
  border-bottom: 1px solid black;
}

.section-one_inside-left h1 {
  margin: 20px 0px;
  font-weight: 800;
  font-size: 4.5rem;
}

.section-one_inside-right {
  display: flex;
  width: 50%;
  max-height: 100%;
  height: 80%;
  align-items: center;
  justify-content: center;
}

.svg-animation {
  width: 100%;
  height: 100%;
  position: relative;
}

.gra {
  --time: 20s;
  --amount: 1;
  --fill: #56cbb9;
}
.video-div {
  position: absolute;
  z-index: 0;
  top: 20%;
  right: 0%;
  width: 50%;
  height: 80%;
}
.video-div video {
  width: 500px;
  height: 500px;
}

.section-one_inside-right img {
  width: 100%;
  height: 90%;
}

.banner {
  /* width="593" height="690" */
  width: 593px;
  height: 690px;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .section-one {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    background-color: #fffdfe;
    z-index: auto;
  }

  .section-one_inside {
    display: flex;
    width: 100%;
    margin: 140px 16px;
    flex-wrap: wrap;
    justify-content: center;
  }

  .section-one_inside-left {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
  }

  .section-one_inside-left span {
    font-weight: 400;
    color: #009985;
    font-size: 1.5rem;
  }

  .section-one_button {
    margin-top: 5rem;
  }

  .section-one_button span {
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 2.5rem;
    color: black;
    text-transform: uppercase;
    padding: 20px 20px 20px 0px;
    border-bottom: 1.5px solid #000;
  }

  .border-line-button {
    margin-top: 18px;
    width: 50%;
    border-bottom: 1px solid black;
  }

  .section-one_inside-left h1 {
    margin: 20px 0px;
    font-weight: 800;
    font-size: 2.3rem;
  }

  .animation svg {
    max-width: 100%;
    max-height: 100%;
  }
  .section-one_inside-right {
    display:  none;
    width: 50%;
    max-height: 100%;
    height: 80%;
    align-items: center;
    justify-content: center;
  }
  .video-div {
    display: none;
    width: 100%;
    height: 400px;
  }
  .video-div video {
    display: none;
    width: 400px;
    height: 400px;
  }
  .svg-animation {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .gra {
    --time: 20s;
    --amount: 1;
    --fill: #56cbb9;
  }

  .section-one_inside-right img {
    width: 100%;
    height: 90%;
  }

  .banner {
    /* width="593" height="690" */
    width: 593px;
    height: 690px;
  }
}
@media screen and (min-width: 481px) and (max-width: 767px) {
  .section-one {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    background-color: #fffdfe;
    z-index: auto;
  }

  .section-one_inside {
    display: flex;
    width: 100%;
    margin: 80px 16px;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .section-one_inside-left {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
  }

  .section-one_inside-left span {
    font-weight: 400;
    color: #009985;
    font-size: 1.5rem;
  }

  .section-one_button {
    margin-top: 3rem;
  }

  .section-one_button span {
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 2.5rem;
    color: black;
    text-transform: uppercase;
    padding: 20px 20px 20px 0px;
    border-bottom: 1.5px solid #000;
  }

  .border-line-button {
    margin-top: 18px;
    width: 50%;
    border-bottom: 1px solid black;
  }

  .section-one_inside-left h1 {
    margin: 20px 0px;
    font-weight: 800;
    font-size: 2.3rem;
  }

  .animation svg {
    max-width: 100%;
    max-height: 100%;
  }
  .section-one_inside-right {
    display: none;
    width: 100%;
    max-height: 100%;
    height: 80%;
    align-items: center;
    justify-content: center;
  }
  .video-div {
    display: none;
    width: 50%;
    margin-top: 160px;
    justify-content: center;
    height: auto;
  }
  .video-div video {
    display: flex;
    width: 60%;
    height: 60%;
    background-color: #000;
  }

  .svg-animation {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .gra {
    --time: 20s;
    --amount: 1;
    --fill: #56cbb9;
  }

  .section-one_inside-right img {
    width: 100%;
    height: 90%;
  }

  .banner {
    /* width="593" height="690" */
    width: 593px;
    height: 690px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .section-one {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    background-color: #fffdfe;
    z-index: auto;
  }

  .section-one_inside {
    display: flex;
    width: 100%;
    margin: 30px 16px 80px 16px;
    flex-wrap: wrap;
    justify-content: center;
  }

  .section-one_inside-left {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
  }

  .section-one_inside-left span {
    font-weight: 400;
    color: #009985;
    font-size: 1.5rem;
  }

  .section-one_button {
    margin-top: 5rem;
  }

  .section-one_button span {
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 2.5rem;
    color: black;
    text-transform: uppercase;
    padding: 20px 20px 20px 0px;
    border-bottom: 1.5px solid #000;
  }

  .border-line-button {
    margin-top: 18px;
    width: 50%;
    border-bottom: 1px solid black;
  }

  .section-one_inside-left h1 {
    margin: 20px 0px;
    font-weight: 800;
    font-size: 2.3rem;
  }

  .animation svg {
    max-width: 100%;
    max-height: 100%;
  }
  .section-one_inside-right {
    display: flex;
    width: 50%;
    max-height: 100%;
    height: 80%;
    align-items: center;
    justify-content: center;
  }
  .video-div {
    display: none;
    width: 100%;
    height: 400px;
  }
  .video-div video {
    width: 100%;
    height: 100%;
  }
  .svg-animation {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .gra {
    --time: 20s;
    --amount: 1;
    --fill: #56cbb9;
  }

  .section-one_inside-right img {
    width: 100%;
    height: 90%;
  }

  .banner {
    /* width="593" height="690" */
    width: 593px;
    height: 690px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1279px){
  .section-one {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    background-color: #fffdfe;
    z-index: auto;
  }

  .animation svg {
    max-width: 100%;
    max-height: 100%;
  }
  .section-one_inside {
    display: flex;
    width: 100%;
    margin: 30px 80px;
    justify-content: space-between;
  }

  .section-one_inside-left {
    display: flex;
    flex-direction: column;
    width: 55%;
    justify-content: center;
  }

  .section-one_inside-left span {
    font-weight: 400;
    color: #009985;
    font-size: 1.5rem;
  }

  .section-one_button {
    margin-top: 5rem;
  }

  .section-one_button span {
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 2.5rem;
    color: black;
    text-transform: uppercase;
    padding: 20px 20px 20px 0px;
    border-bottom: 1.5px solid #000;
  }

  .border-line-button {
    margin-top: 18px;
    width: 50%;
    border-bottom: 1px solid black;
  }

  .section-one_inside-left h1 {
    margin: 60px 0px 0px 0px;
    font-weight: 800;
    font-size: 4rem;
  }

  .section-one_inside-right {
    display: flex;
    width: 50%;
    max-height: 100%;
    height: 80%;
    align-items: center;
    justify-content: center;
  }

  .svg-animation {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .gra {
    --time: 20s;
    --amount: 1;
    --fill: #56cbb9;
  }
  .video-div {
    position: absolute;
    z-index: 0;
    top: 20%;
    right: 0%;
    width: 50%;
    height: 80%;
  }
  .video-div video {
    width: 500px;
    height: 500px;
  }

  .section-one_inside-right img {
    width: 100%;
    height: 90%;
  }

  .banner {
    /* width="593" height="690" */
    width: 593px;
    height: 690px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1439px){
  .section-one {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    background-color: #fffdfe;
    z-index: auto;
  }

  .animation svg {
    max-width: 100%;
    max-height: 100%;
  }
  .section-one_inside {
    display: flex;
    width: 100%;
    margin: 30px 120px;
    justify-content: space-between;
  }

  .section-one_inside-left {
    display: flex;
    flex-direction: column;
    width: 60%;
    justify-content: center;
  }

  .section-one_inside-left span {
    font-weight: 400;
    color: #009985;
    font-size: 1.5rem;
  }
  .section-one_inside-left h1 {

    margin: 60px 0px 0px 0px;
    font-weight: 800;
    font-size: 4.5rem;
  }
  .section-one_button {
    margin-top: 5rem;
  }

  .section-one_button span {
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 2.5rem;
    color: black;
    text-transform: uppercase;
    padding: 20px 20px 20px 0px;
    border-bottom: 1.5px solid #000;
  }

  .border-line-button {
    margin-top: 18px;
    width: 50%;
    border-bottom: 1px solid black;
  }

  .section-one_inside-right {
    display: flex;
    width: 50%;
    max-height: 100%;
    height: 80%;
    align-items: center;
    justify-content: center;
  }

  .svg-animation {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .gra {
    --time: 20s;
    --amount: 1;
    --fill: #56cbb9;
  }
  .video-div {
    display: flex;
    width: 40%;
    align-items: flex-start;
    justify-content: flex-end;

  }
  .video-div video {
    width: 500px;
    height: 500px;
  }

  .section-one_inside-right img {
    width: 100%;
    height: 90%;
  }

  .banner {
    /* width="593" height="690" */
    width: 593px;
    height: 690px;
  }

}
@media screen and (min-width: 1440px) and (max-width: 1919px){
  .section-one {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    background-color: #fffdfe;
    z-index: auto;
  }

  .animation svg {
    max-width: 100%;
    max-height: 100%;
  }
  .section-one_inside {
    display: flex;
    width: 100%;
    margin: 30px 120px;
    justify-content: space-between;
  }

  .section-one_inside-left {
    display: flex;
    flex-direction: column;
    width: 55%;
    justify-content: center;
  }

  .section-one_inside-left span {
    font-weight: 400;
    color: #009985;
    font-size: 1.5rem;
  }

  .section-one_button {
    margin-top: 5rem;
  }

  .section-one_button span {
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 2.5rem;
    color: black;
    text-transform: uppercase;
    padding: 20px 20px 20px 0px;
    border-bottom: 1.5px solid #000;
  }

  .border-line-button {
    margin-top: 18px;
    width: 50%;
    border-bottom: 1px solid black;
  }

  .section-one_inside-left h1 {
    margin: 70px 0px 0px 0px;
    font-weight: 800;
    font-size: 4.5rem;
  }

  .section-one_inside-right {
    display: flex;
    width: 40%;
    max-height: 100%;
    height: 80%;
    align-items: center;
    justify-content: center;
  }

  .svg-animation {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .gra {
    --time: 20s;
    --amount: 1;
    --fill: #56cbb9;
  }
  .video-div {
    display: flex;
    width: 40%;
    justify-content: flex-end;

  }
  .video-div video {
    width: 500px;
    height: 500px;
  }

  .section-one_inside-right img {
    width: 100%;
    height: 90%;
  }

  .banner {
    /* width="593" height="690" */
    width: 593px;
    height: 690px;
  }

}
@media screen and (min-width: 1920px) {
  .section-one {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    background-color: #fffdfe;
    z-index: auto;
  }

  .animation svg {
    max-width: 100%;
    max-height: 100%;
  }
  .section-one_inside {
    display: flex;
    width: 100%;

    margin: 120px  120px 0px 120px;
    justify-content: space-between;
  }

  .section-one_inside-left {
    display: flex;
    flex-direction: column;
    width: 55%;
    justify-content: center;
  }

  .section-one_inside-left span {
    font-weight: 400;
    color: #009985;
    font-size: 1.5rem;
  }

  .section-one_button {
    margin-top: 5rem;
  }

  .section-one_button span {
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 2.5rem;
    color: black;
    text-transform: uppercase;
    padding: 20px 20px 20px 0px;
    border-bottom: 1.5px solid #000;
  }

  .border-line-button {
    margin-top: 18px;
    width: 50%;
    border-bottom: 1px solid black;
  }

  .section-one_inside-left h1 {
    margin: 20px 0px;
    font-weight: 800;
    font-size: 4.5rem;
  }

  .section-one_inside-right {
    display: flex;
    width: 50%;
    max-height: 100%;
    height: 80%;
    align-items: center;
    justify-content: center;
  }

  .svg-animation {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .gra {
    --time: 20s;
    --amount: 1;
    --fill: #56cbb9;
  }
  .video-div {
    display: flex;
    width: 40%;
    justify-content: flex-end;

  }
  .video-div video {
    width: 700px;
    height: 650px;
  }

  .section-one_inside-right img {
    width: 100%;
    height: 90%;
  }

  .banner {
    /* width="593" height="690" */
    width: 593px;
    height: 690px;
  }

}




