html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: #000;
}

li {
  list-style: none;
}
ul{
  padding: 0;
}

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-color: #fffdfe;
}

footer {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  margin-top: auto;
}





@media screen and (min-height:320px) and (max-width: 767px) {
  .buger-menu__container {
    display: block;
    margin-right: 8px;
  }

  b
  .bm-burger-button {
    display: block;
  }

  .desctop {
    display: none;
  }
  .header {
    display: flex;
    width: 100%;
    margin: 0px;
    padding: 0px 16px;
    align-items: center;
    justify-content: space-between;
    background-color: #fffdfe;
  }

  .langChang {
    display: none;
  }

  .header img {
    width: 61px;
    height: 50px;
  }

  .header ul {
    display: none;
    width: 100%;
    height: calc(100vh - 60px);
    z-index: 9999;
    overflow: hidden;
    justify-content: flex-start;
  }



  .header li {
    height: 60px;
    padding-top: 30px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #121212;
  }

  .langChageBurger {
    margin-top: 40px;
  }
  .buger-menu__container {
    display: block;
  }
}
@media screen and (min-height:768px) and (max-width: 1023px) {

  header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    background-color: #fffdfe;
  }
  .header {
    display: flex;
    width: 100%;
    margin: 0px 0px 0px 0px;
    padding: 0px 16px;
    align-items: center;
    justify-content: space-between;
    background-color: #fffdfe;
  }
  .header-logo{
    display: flex;
    width: 10%;
  }
  .header img {
    width: 84px;
    height: 73px;
  }

  .desctop a {
    padding: 0px;
    position: relative;
    z-index: 4;
  }


  .desctop{
    display: flex;
    margin: 0px;
  }

  .lang {
    font-size: 1.4rem;
    font-weight: 700;
    cursor: pointer;
  }


  .buger-menu__container {
    display: none;
  }

}
@media screen and (min-height:1024px) and (max-width: 1279px) {
  header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    background-color: #fffdfe;
  }
  .header {
    display: flex;
    width: 100%;
    margin: 0px 0px 0px 0px;
    padding: 0px 16px;
    align-items: center;
    justify-content: space-between;
    background-color: #fffdfe;
  }

  .header img {
    width: 84px;
    height: 73px;
  }
  .header-logo{
    display: flex;
    width: 10%;
  }
  .desctop a {
    padding: 0px;
    position: relative;
    z-index: 4;
  }


  .desctop{
    display: flex;
    margin: 0px;
  }

  .lang {
    font-size: 1.4rem;
    font-weight: 700;
    cursor: pointer;
  }


  .buger-menu__container {
    display: none;
  }

}
@media screen and (min-height:1280px) and (max-width: 1439px) {

  header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    background-color: #fffdfe;
  }

  .header {
    display: flex;
    width: 100%;
    margin: 0px;
    padding: 0px 80px;
    align-items: center;
    justify-content: space-between;
    background-color: #fffdfe;
  }
  .header-logo{
    display: flex;
    width: 10%;
  }
  .header img {
    width: 84px;
    height: 73px;
  }

  .header a {
    padding: 10px;
    position: relative;
    z-index: 4;
  }
  .desctop a{
    display: flex;
    width: auto;
    justify-content: space-between;
    padding: 0px;
    margin: 0px;
  }
  .header ul {
    display: flex;
    width: 60%;
    font-weight: 500;
    line-height: 2rem;
    font-size: 1.2rem;
    margin: 0px;
    justify-content: space-between;
  }

  .langChang{
    display: flex;
    width: 10%;
    justify-content: flex-start;
  }
  .lang {
    display: flex;
    width: 80%;
    font-size: 1.4rem;
    font-weight: 700;
    cursor: pointer;
  }


  .buger-menu__container {
    display: none;
  }
}
@media screen and (min-height:1440px) and (max-width: 1919px) {

  header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    background-color: #fffdfe;
  }

  .header {
    display: flex;
    width: 100%;
    margin: 0px;
    padding: 0px 120px;
    align-items: center;
    justify-content: space-between;
    background-color: #fffdfe;
  }
  .header-logo{
    display: flex;
    width: 50%;
  }
  .header img {
    width: 84px;
    height: 73px;
  }

  .header a {
    padding: 10px;
    position: relative;
    z-index: 4;
  }
  .desctop a{
    display: flex;
    width: auto;
    justify-content: space-between;
    padding: 0px;
    margin: 0px;
  }
  .header ul {
    display: flex;
    width: 60%;
    font-weight: 500;
    line-height: 2rem;
    font-size: 1.2rem;
    margin: 0px;
    justify-content: space-between;
  }

  .langChang{
    display: flex;
    width: 10%;
    justify-content: flex-start;
  }
  .lang {
    display: flex;
    width: 80%;
    font-size: 1.4rem;
    font-weight: 700;
    cursor: pointer;
  }


  .buger-menu__container {
    display: none;
  }
}



