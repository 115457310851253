.section-three {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.section-three_inside {
    display: flex;
    width: 100%;
    margin: 30px 120px;
    justify-content: space-between;
}

.grid-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.section-three_inside-block {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.button-cont {
    display: flex;
    justify-content: center;
}

.section-three_inside-block button {
    width: 300px;
    padding: 14px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #121212;
    margin: 40px 0px;
}

.section-three_inside-block button:hover {
    width: 300px;
    padding: 14px;
    background-color: #f7f6f5;
    border: none;
    cursor: pointer;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #121212;
    box-shadow: 0px 0px 8px -4px rgba(34, 60, 80, 0.18);
}

.section-three_inside-block h3 {
    display: flex;
    align-self: flex-start;
    font-weight: 800;
    font-size: 2.5rem;
    line-height: 6rem;
}

.angry-grid {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    height: 100vh;
    margin-bottom: 30px;
}

#item-0 {
    background-image: url(../../../assets/frameThree.jpeg);

    object-fit: cover;
    background-repeat: no-repeat;
    background-position: center;
    grid-row: 1 / 3;
    position: relative;
    background-size: cover;
}

.content-block {
    position: absolute;
    z-index: 123;
    color: white;
    display: flex;
    width: 90%;
    top: 0;
    height: 100%;
    padding: 1rem;
    gap: 4rem;
    flex-direction: column;
    justify-content: center;
}

.content-title h2 {
    font-size: 2rem;
}

.content-title h5 {
    font-size: 1rem;
    font-weight: 600;
}

.content-list {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.content-text h5 {
    font-size: 1.25rem;
}

.content-text p {
    padding: 0;
    margin: 0;
    font-size: 0.75rem;
    font-weight: 600;
}

.content-list h5 {
    padding: 0;
    margin: 0;
}

.content-list .content-text {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
}

#item-0::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
}

#item-1 {
    background-image: url(../../../assets/frameFive.svg);

    object-fit: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    grid-row: 1/2;
    position: relative;
}

#item-1::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
}

#item-2 {
    background-image: url(../../../assets/frameFour.JPG);
    background-repeat: no-repeat;
    background-position: center;
    object-fit: cover;
    grid-row: 2/3;
    grid-column: 2;
    background-size: cover;
    position: relative;
}

#item-2::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
}

#item-3 {
    background-image: url(../../../assets/frameOne.svg);

    background-repeat: no-repeat;
    background-position: center;
    object-fit: cover;
    position: relative;
    grid-column: 3;
    grid-row: 3/6;
    background-size: cover;
}

#item-3::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
}

#item-4 {
    background-image: url(../../../assets/frameTwo.jpg);
    background-repeat: no-repeat;
    background-position: center;
    object-fit: cover;
    grid-row: 1 / 3;
    position: relative;
    grid-column: 3;
    background-size: cover;
}

#item-4::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
}

#item-5 {
    background-image: url(../../../assets/frameSix.jpg);
    background-repeat: no-repeat;
    background-position: center;
    object-fit: cover;
    grid-column: 1/3;
    position: relative;
    grid-row: 3/6;
    background-size: cover;
}

#item-5::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
}

.carousel {
    display: none;
}

@media screen and (min-width: 320px) and (max-width: 768px) {
    .section-three {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }

    .section-three_inside {
        display: flex;
        width: 100%;
        margin: 30px;
        justify-content: space-between;
    }

    .grid-container {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .section-three_inside-block {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    .button-cont {
        display: flex;
        justify-content: center;
    }

    .section-three_inside-block button {
        width: 300px;
        padding: 14px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: #121212;
        margin: 40px 0px;
    }

    .section-three_inside-block button:hover {
        width: 300px;
        padding: 14px;
        background-color: #f7f6f5;
        border: none;
        cursor: pointer;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: #121212;
        box-shadow: 0px 0px 8px -4px rgba(34, 60, 80, 0.18);
    }

    .section-three_inside-block h3 {
        display: flex;
        align-self: flex-start;
        font-weight: 800;
        font-size: 2rem;
        margin-left: 12px;
        line-height: 3rem;
    }

    .angry-grid {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;
        height: 100vh;
        margin-bottom: 30px;
    }

    .grid-container {
        display: none;
    }

    .carousel {
        display: block;
    }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
    .section-three {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }

    .section-three_inside {
        display: flex;
        width: 100%;
        margin: 30px 120px;
        justify-content: space-between;
    }

    .grid-container {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .section-three_inside-block {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    .button-cont {
        display: flex;
        justify-content: center;
    }

    .section-three_inside-block button {
        width: 300px;
        padding: 14px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: #121212;
        margin: 40px 0px;
    }

    .section-three_inside-block button:hover {
        width: 300px;
        padding: 14px;
        background-color: #f7f6f5;
        border: none;
        cursor: pointer;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: #121212;
        box-shadow: 0px 0px 8px -4px rgba(34, 60, 80, 0.18);
    }

    .section-three_inside-block h3 {
        display: flex;
        align-self: flex-start;
        font-weight: 800;
        font-size: 2.5rem;
        line-height: 6rem;
    }

    .angry-grid {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;
        height: 100vh;
        margin-bottom: 30px;
    }

    #item-0 {
        background-image: url(../../../assets/frameThree.jpeg);

        object-fit: cover;
        background-repeat: no-repeat;
        background-position: center;
        grid-row: 1 / 3;
        position: relative;
        background-size: cover;
    }

    .content-block {
        z-index: 123;
        color: white;
        display: flex;
        width: 100%;
        height: 100%;
        padding: 0.2rem;
        gap: 0rem;
        flex-direction: column;
        justify-content: center;
    }

    .content-title h2 {
        font-size: 1rem;
        margin: 0.5em 0em;
    }

    .content-title h5 {
        font-size: 0.5rem;
        margin: 0;
        display: flex;
        width: 100%;
        font-weight: 600;
    }

    .content-list {
        display: none;
        flex-direction: column;
        gap: 1.5rem;
    }

    .content-text h5 {
        font-size: 1.25rem;
    }

    .content-text p {
        padding: 0;
        margin: 0;
        font-size: 0.75rem;
        font-weight: 600;
    }

    .content-list h5 {
        padding: 0;
        margin: 0;
    }

    .content-list .content-text {
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0;
    }

    #item-1 {
        background-image: url(../../../assets/frameFive.svg);

        object-fit: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        grid-row: 1/2;
        position: relative;
    }

    #item-1::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }

    #item-2 {
        background-image: url(../../../assets/frameFour.JPG);
        background-repeat: no-repeat;
        background-position: center;
        object-fit: cover;
        grid-row: 2/3;
        grid-column: 2;
        background-size: cover;
        position: relative;
    }

    #item-2::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }

    #item-3 {
        background-image: url(../../../assets/frameOne.svg);

        background-repeat: no-repeat;
        background-position: center;
        object-fit: cover;
        position: relative;
        grid-column: 3;
        grid-row: 3/6;
        background-size: cover;
    }

    #item-3::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }

    #item-4 {
        background-image: url(../../../assets/frameTwo.jpg);
        background-repeat: no-repeat;
        background-position: center;
        object-fit: cover;
        grid-row: 1 / 3;
        position: relative;
        grid-column: 3;
        background-size: cover;
    }

    #item-4::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }

    #item-5 {
        background-image: url(../../../assets/frameSix.jpg);
        background-repeat: no-repeat;
        background-position: center;
        object-fit: cover;
        grid-column: 1/3;
        position: relative;
        grid-row: 3/6;
        background-size: cover;
    }

    #item-5::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }

    .carousel {
        display: none;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
    .section-three {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }

    .section-three_inside {
        display: flex;
        width: 100%;
        margin: 30px 120px;
        justify-content: space-between;
    }

    .grid-container {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .section-three_inside-block {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    .button-cont {
        display: flex;
        justify-content: center;
    }

    .section-three_inside-block button {
        width: 300px;
        padding: 14px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: #121212;
        margin: 40px 0px;
    }

    .section-three_inside-block button:hover {
        width: 300px;
        padding: 14px;
        background-color: #f7f6f5;
        border: none;
        cursor: pointer;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: #121212;
        box-shadow: 0px 0px 8px -4px rgba(34, 60, 80, 0.18);
    }

    .section-three_inside-block h3 {
        display: flex;
        align-self: flex-start;
        font-weight: 800;
        font-size: 2.5rem;
        line-height: 6rem;
    }

    .angry-grid {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;
        height: 100vh;
        margin-bottom: 30px;
    }

    #item-0 {
        background-image: url(../../../assets/frameThree.jpeg);

        object-fit: cover;
        background-repeat: no-repeat;
        background-position: center;
        grid-row: 1 / 3;
        position: relative;
        background-size: cover;
    }

    .content-block {
        z-index: 123;
        color: white;
        display: flex;
        width: 100%;
        height: 100%;
        padding: 0.2rem;
        gap: 0rem;
        flex-direction: column;
        justify-content: center;
    }

    .content-title {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
    }

    .content-title h2 {
        font-size: 1rem;
        margin: 0.5em 0em;
    }

    .content-title h5 {
        font-size: 0.5rem;
        margin: 0;
        display: flex;
        width: 100%;
        font-weight: 600;
    }

    .content-list {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 8px 0px;
    }

    .content-text h5 {
        font-size: 1rem;
    }

    .content-text p {
        padding: 0;
        margin: 0;
        font-size: 0.5rem;
        font-weight: 600;
    }

    .content-list h5 {
        padding: 0;
        margin: 0;
    }

    .content-list .content-text {
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0;
    }

    #item-1 {
        background-image: url(../../../assets/frameFive.svg);

        object-fit: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        grid-row: 1/2;
        position: relative;
    }

    #item-1::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }

    #item-2 {
        background-image: url(../../../assets/frameFour.JPG);
        background-repeat: no-repeat;
        background-position: center;
        object-fit: cover;
        grid-row: 2/3;
        grid-column: 2;
        background-size: cover;
        position: relative;
    }

    #item-2::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }

    #item-3 {
        background-image: url(../../../assets/frameOne.svg);

        background-repeat: no-repeat;
        background-position: center;
        object-fit: cover;
        position: relative;
        grid-column: 3;
        grid-row: 3/6;
        background-size: cover;
    }

    #item-3::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }

    #item-4 {
        background-image: url(../../../assets/frameTwo.jpg);
        background-repeat: no-repeat;
        background-position: center;
        object-fit: cover;
        grid-row: 1 / 3;
        position: relative;
        grid-column: 3;
        background-size: cover;
    }

    #item-4::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }

    #item-5 {
        background-image: url(../../../assets/frameSix.jpg);
        background-repeat: no-repeat;
        background-position: center;
        object-fit: cover;
        grid-column: 1/3;
        position: relative;
        grid-row: 3/6;
        background-size: cover;
    }

    #item-5::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }

    .carousel {
        display: none;
    }
}

@media screen and (min-width: 1280px) and (max-width: 1439px) {
    .section-three {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }

    .section-three_inside {
        display: flex;
        width: 100%;
        margin: 30px 120px;
        justify-content: space-between;
    }

    .grid-container {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .section-three_inside-block {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    .button-cont {
        display: flex;
        justify-content: center;
    }

    .section-three_inside-block button {
        width: 300px;
        padding: 14px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: #121212;
        margin: 40px 0px;
    }

    .section-three_inside-block button:hover {
        width: 300px;
        padding: 14px;
        background-color: #f7f6f5;
        border: none;
        cursor: pointer;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: #121212;
        box-shadow: 0px 0px 8px -4px rgba(34, 60, 80, 0.18);
    }

    .section-three_inside-block h3 {
        display: flex;
        align-self: flex-start;
        font-weight: 800;
        font-size: 2.5rem;
        line-height: 6rem;
    }

    .angry-grid {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;
        height: 100vh;
        margin-bottom: 30px;
    }

    #item-0 {
        background-image: url(../../../assets/frameThree.jpeg);

        object-fit: cover;
        background-repeat: no-repeat;
        background-position: center;
        grid-row: 1 / 3;
        position: relative;
        background-size: cover;
    }

    .content-block {
        z-index: 123;
        color: white;
        display: flex;
        width: 100%;
        height: 100%;
        padding: 0.2rem;
        gap: 0rem;
        flex-direction: column;
        justify-content: center;
    }

    .content-title {
        display: flex;
        width: 100%;
        height: auto;
        flex-direction: column;
    }

    .content-title h2 {
        font-size: 1rem;
        margin: 0.5em 0em;
    }

    .content-title h5 {
        font-size: 0.6rem;
        margin: 0;
        display: flex;
        width: 100%;
        font-weight: 600;
    }

    .content-list {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 8px 0px;
    }

    .content-text h5 {
        font-size: 1rem;
    }

    .content-text p {
        padding: 0;
        margin: 0;
        font-size: 0.6rem;
        font-weight: 600;
    }

    .content-list h5 {
        padding: 0;
        margin: 0;
    }

    .content-list .content-text {
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0;
    }

    #item-1 {
        background-image: url(../../../assets/frameFive.svg);

        object-fit: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        grid-row: 1/2;
        position: relative;
    }

    #item-1::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }

    #item-2 {
        background-image: url(../../../assets/frameFour.JPG);
        background-repeat: no-repeat;
        background-position: center;
        object-fit: cover;
        grid-row: 2/3;
        grid-column: 2;
        background-size: cover;
        position: relative;
    }

    #item-2::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }

    #item-3 {
        background-image: url(../../../assets/frameOne.svg);

        background-repeat: no-repeat;
        background-position: center;
        object-fit: cover;
        position: relative;
        grid-column: 3;
        grid-row: 3/6;
        background-size: cover;
    }

    #item-3::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }

    #item-4 {
        background-image: url(../../../assets/frameTwo.jpg);
        background-repeat: no-repeat;
        background-position: center;
        object-fit: cover;
        grid-row: 1 / 3;
        position: relative;
        grid-column: 3;
        background-size: cover;
    }

    #item-4::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }

    #item-5 {
        background-image: url(../../../assets/frameSix.jpg);
        background-repeat: no-repeat;
        background-position: center;
        object-fit: cover;
        grid-column: 1/3;
        position: relative;
        grid-row: 3/6;
        background-size: cover;
    }

    #item-5::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }

    .carousel {
        display: none;
    }
}

@media screen and (min-width: 1440px) and (max-width: 1919px) {
    .section-three {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }

    .section-three_inside {
        display: flex;
        width: 100%;
        margin: 30px 120px;
        justify-content: space-between;
    }

    .grid-container {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .section-three_inside-block {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    .button-cont {
        display: flex;
        justify-content: center;
    }

    .section-three_inside-block button {
        width: 300px;
        padding: 14px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: #121212;
        margin: 40px 0px;
    }

    .section-three_inside-block button:hover {
        width: 300px;
        padding: 14px;
        background-color: #f7f6f5;
        border: none;
        cursor: pointer;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: #121212;
        box-shadow: 0px 0px 8px -4px rgba(34, 60, 80, 0.18);
    }

    .section-three_inside-block h3 {
        display: flex;
        align-self: flex-start;
        font-weight: 800;
        font-size: 2.5rem;
        line-height: 6rem;
    }

    .angry-grid {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;
        height: 100vh;
        margin-bottom: 30px;
    }

    #item-0 {
        background-image: url(../../../assets/frameThree.jpeg);

        object-fit: cover;
        background-repeat: no-repeat;
        background-position: center;
        grid-row: 1 / 3;
        position: relative;
        background-size: cover;
    }

    .content-block {
        z-index: 123;
        color: white;
        display: flex;
        width: 100%;
        height: 100%;
        padding: 0.2rem;
        gap: 0rem;
        flex-direction: column;
        justify-content: center;
    }

    .content-title {
        display: flex;
        width: 100%;
        height: 100%;
        gap: 0;
        flex-direction: column;
    }

    .content-title h2 {
        font-size: 1.2rem;
        margin: 0.5em 0em;
    }

    .content-title h5 {
        font-size: 0.8rem;
        margin: 0;
        display: flex;
        width: 100%;
        font-weight: 600;
    }

    .content-list {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 8px 0px;
    }

    .content-text h5 {
        font-size: 1.2rem;
    }

    .content-text p {
        padding: 0;
        margin: 0;
        font-size: 0.8rem;
        font-weight: 600;
    }

    .content-list h5 {
        padding: 0;
        margin: 0;
    }

    .content-list .content-text {
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0;
    }

    #item-1 {
        background-image: url(../../../assets/frameFive.svg);
        object-fit: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        grid-row: 1/2;
        position: relative;
    }

    #item-1::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }

    #item-2 {
        background-image: url(../../../assets/frameFour.JPG);
        background-repeat: no-repeat;
        background-position: center;
        object-fit: cover;
        grid-row: 2/3;
        grid-column: 2;
        background-size: cover;
        position: relative;
    }

    #item-2::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }

    #item-3 {
        background-image: url(../../../assets/frameOne.svg);

        background-repeat: no-repeat;
        background-position: center;
        object-fit: cover;
        position: relative;
        grid-column: 3;
        grid-row: 3/6;
        background-size: cover;
    }

    #item-3::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }

    #item-4 {
        background-image: url(../../../assets/frameTwo.jpg);
        background-repeat: no-repeat;
        background-position: center;
        object-fit: cover;
        grid-row: 1 / 3;
        position: relative;
        grid-column: 3;
        background-size: cover;
    }

    #item-4::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }

    #item-5 {
        background-image: url(../../../assets/frameSix.jpg);
        background-repeat: no-repeat;
        background-position: center;
        object-fit: cover;
        grid-column: 1/3;
        position: relative;
        grid-row: 3/6;
        background-size: cover;
    }

    #item-5::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }

    .carousel {
        display: none;
    }
    
}

@media screen  and (min-width: 1920px) {
    .section-three {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }

    .section-three_inside {
        display: flex;
        width: 100%;
        margin: 30px 120px;
        justify-content: space-between;
    }

    .grid-container {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .section-three_inside-block {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    .button-cont {
        display: flex;
        justify-content: center;
    }

    .section-three_inside-block button {
        width: 300px;
        padding: 14px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: #121212;
        margin: 40px 0px;
    }

    .section-three_inside-block button:hover {
        width: 300px;
        padding: 14px;
        background-color: #f7f6f5;
        border: none;
        cursor: pointer;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: #121212;
        box-shadow: 0px 0px 8px -4px rgba(34, 60, 80, 0.18);
    }

    .section-three_inside-block h3 {
        display: flex;
        align-self: flex-start;
        font-weight: 800;
        font-size: 2.5rem;
        line-height: 6rem;
    }

    .angry-grid {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;
        height: 100vh;
        margin-bottom: 30px;
    }

    #item-0 {
        background-image: url(../../../assets/frameThree.jpeg);

        object-fit: cover;
        background-repeat: no-repeat;
        background-position: center;
        grid-row: 1 / 3;
        position: relative;
        background-size: cover;
    }

    .content-block {
        z-index: 123;
        color: white;
        display: flex;
        width: 100%;
        height: 100%;
        padding: 0.2rem;
        gap: 0rem;
        flex-direction: column;
        justify-content: center;
    }

    .content-title {
        display: flex;
        width: 100%;
        height: auto;
        padding: 0;
        justify-content: center;
        flex-direction: column;
    }

    .content-title h2 {
        font-size: 1.4rem;
        margin: 0.5em 0em;
    }

    .content-title h5 {
        font-size: 1rem;
        margin: 0;
        display: flex;
        width: 100%;
        font-weight: 600;
    }

    .content-list {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 8px 0px;
    }

    .content-text h5 {
        font-size: 1.4rem;
    }

    .content-text p {
        padding: 0;
        margin: 0;
        font-size: 1rem;
        font-weight: 600;
    }

    .content-list h5 {
        padding: 0;
        margin: 0;
    }

    .content-list .content-text {
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0;
    }

    #item-1 {
        background-image: url(../../../assets/frameFive.svg);

        object-fit: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        grid-row: 1/2;
        position: relative;
    }

    #item-1::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }

    #item-2 {
        background-image: url(../../../assets/frameFour.JPG);
        background-repeat: no-repeat;
        background-position: center;
        object-fit: cover;
        grid-row: 2/3;
        grid-column: 2;
        background-size: cover;
        position: relative;
    }

    #item-2::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }

    #item-3 {
        background-image: url(../../../assets/frameOne.svg);

        background-repeat: no-repeat;
        background-position: center;
        object-fit: cover;
        position: relative;
        grid-column: 3;
        grid-row: 3/6;
        background-size: cover;
    }

    #item-3::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }

    #item-4 {
        background-image: url(../../../assets/frameTwo.jpg);
        background-repeat: no-repeat;
        background-position: center;
        object-fit: cover;
        grid-row: 1 / 3;
        position: relative;
        grid-column: 3;
        background-size: cover;
    }

    #item-4::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }

    #item-5 {
        background-image: url(../../../assets/frameSix.jpg);
        background-repeat: no-repeat;
        background-position: center;
        object-fit: cover;
        grid-column: 1/3;
        position: relative;
        grid-row: 3/6;
        background-size: cover;
    }

    #item-5::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }

    .carousel {
        display: none;
        }
    
}
