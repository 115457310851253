.buger-menu__container {
    display: none;
}

.header {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    padding: 16px 120px 0px 120px;
    align-items: center;
    justify-content: space-between;
    background-color: #fffdfe;
}

.header-logo {
    display: flex;
    width: 20%;
    justify-content: flex-start;
}

.header ul {
    display: flex;
    width: 60%;
    font-weight: 500;
    line-height: 2rem;
    font-size: 1.2rem;
    margin: 0px;
    justify-content: space-between;
}

.langChange {
    cursor: pointer;
}


@media screen and (min-width: 1920px) {
    .buger-menu__container {
        display: none;
    }

    .header {
        box-sizing: border-box;
        display: flex;
        width: 100%;
        padding: 16px 120px 0px 120px;
        align-items: center;
        justify-content: space-between;
        background-color: #fffdfe;
    }

    .header-logo {
        display: flex;
        width: 20%;
        justify-content: flex-start;
    }

    .header ul {
        display: flex;
        width: 60%;
        font-weight: 500;
        line-height: 2rem;
        font-size: 1.5rem;
        margin: 0px;
        justify-content: space-between;
    }

    .langChange {
        cursor: pointer;
    }

}


@media screen and (min-width: 1440px) and (max-width: 1919px) {
    .buger-menu__container {
        display: none;
    }

    .header {
        box-sizing: border-box;
        display: flex;
        width: 100%;
        padding: 16px 120px 0px 120px;
        align-items: center;
        justify-content: space-between;
        background-color: #fffdfe;
    }

    .header-logo {
        display: flex;
        width: 20%;
        justify-content: flex-start;
    }

    .header ul {
        display: flex;
        width: 60%;
        font-weight: 500;
        line-height: 2rem;
        font-size: 1.2rem;
        margin: 0px;
        justify-content: space-between;
    }

    .langChange {
        cursor: pointer;
    }
}

@media screen and (min-width: 1280px) and (max-width: 1439px) {
    .buger-menu__container {
        display: none;
    }

    .header {
        box-sizing: border-box;
        display: flex;
        width: 100%;
        padding: 16px 120px 0px 120px;
        align-items: center;
        justify-content: space-between;
        background-color: #fffdfe;
    }

    .header-logo {
        display: flex;
        width: 20%;
        justify-content: flex-start;
    }

    .header ul {
        display: flex;
        width: 60%;
        font-weight: 500;
        line-height: 2rem;
        font-size: 1.2rem;
        margin: 0px;
        justify-content: space-between;
    }

    .langChange {
        cursor: pointer;
    }
}

@media screen and (min-width: 1280px) and (max-width: 1439px) {
    .buger-menu__container {
        display: none;
    }

    .header {
        box-sizing: border-box;
        display: flex;
        width: 100%;
        padding: 8px 120px 0px 120px;
        align-items: center;
        justify-content: space-between;
        background-color: #fffdfe;
    }

    .header-logo {
        display: flex;
        width: 20%;
        justify-content: flex-start;
    }

    .header ul {
        display: flex;
        width: 60%;
        font-weight: 500;
        line-height: 2rem;
        font-size: 1.2rem;
        margin: 0px;
        justify-content: space-between;
    }

    .langChange {
        cursor: pointer;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
    .buger-menu__container {
        display: none;
    }

    .header {
        box-sizing: border-box;
        display: flex;
        width: 100%;
        padding: 8px 80px 0px 80px;
        align-items: center;
        justify-content: space-between;
        background-color: #fffdfe;
    }

    .header-logo {
        display: flex;
        width: 20%;
        justify-content: flex-start;
    }

    .header ul {
        display: flex;
        width: 60%;
        font-weight: 500;
        line-height: 2rem;
        font-size: 1.2rem;
        margin: 0px;
        justify-content: space-between;
    }

    .langChange {
        cursor: pointer;
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .buger-menu__container {
        display: none;
    }

    .header {
        box-sizing: border-box;
        display: flex;
        width: 100%;
        padding: 8px 16px 0px 16px;
        align-items: center;
        justify-content: space-between;
        background-color: #fffdfe;
    }

    .header-logo {
        display: flex;
        width: 20%;
        justify-content: flex-start;
    }

    .header ul {
        display: flex;
        width: 60%;
        font-weight: 500;
        line-height: 2rem;
        font-size: 1.2rem;
        margin: 0px;
        justify-content: space-between;
    }

    .langChange {
        cursor: pointer;
    }
}

@media screen and (min-width: 481px) and (max-width: 767px) {
    .buger-menu__container {
        display: none;
    }

    .header {
        box-sizing: border-box;
        display: flex;
        width: 100%;
        padding: 8px 16px 0px 16px;
        align-items: center;
        justify-content: space-between;
        background-color: #fffdfe;
    }

    .header-logo {
        display: flex;
        width: 20%;
        justify-content: flex-start;
    }

    .header ul {
        display: flex;
        width: 75%;
        font-weight: 500;
        line-height: 2rem;
        font-size: 1rem;
        margin: 0px;
        justify-content: space-between;
    }

    .langChange {
        cursor: pointer;
    }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
    .buger-menu__container {
        display: block;
    }

    header {
        position: fixed;
        top: 0px;
        left: 0px;
        background-color: #fff;
        z-index: 999;
        background: rgba(255, 255, 255, 0.05);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(6.8px);
    }

    .bm-burger-button {
        display: block;
    }

    .desctop {
        display: none;
    }

    .burger-container {
        display: block;
        width: 100%;
        height: 20vh;
        background-color: black;

    }

    .header {
        box-sizing: border-box;
        display: flex;
        width: 100%;
        padding: 8px 16px 0px 16px;
        align-items: center;
        justify-content: space-between;
        background-color: #fffdfe;
        max-height: 90px;
    }

    .header-logo {
        display: flex;
        width: 20%;
        justify-content: flex-start;
    }

    .header ul {
        display: none;
        width: 60%;
        font-weight: 500;
        line-height: 2rem;
        font-size: 1.2rem;
        margin: 0px;
        justify-content: space-between;
    }

    .buger-menu__container ul {
        padding-top: 0px;
        display: block;
        width: 100%;
        background-color: white;
        height: calc(100vh);
        z-index: 9999;
        overflow: hidden;
        margin-top: 30px;
        justify-content: flex-start;
    }

    .desctop ul {
        display: none;
    }

    .langChange {
        cursor: pointer;
    }
}