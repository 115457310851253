
.footer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding-top: 64px;
}

.footer img {
    width: 84px;
    height: 73px;
}

.footer-inside {
    display: flex;
    width: 100%;
    padding: 10px 120px 80px 120px;
    align-items: center;
    justify-content: space-between;
}

.inside {
    padding: 10px 0px 80px 0px;
}

.inside a {
    text-align: center;
}

.footer-logo {
    display: flex;
    width: fit-content;
    justify-content: flex-start;
}

.footer-links {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    height: auto;
    width: fit-content;
    gap: 40px;
}

.footer-navlinks {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: auto;
    width: 40%;
    gap: 26px;
}

.footer-navlinks ul {
    padding: 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.footer-navlinks a {
    display: flex;
    width: auto;
}

.footer-navlinks li {
    display: flex;
    width: auto;
}

.footer-links a {
    display: flex;
    align-items: flex-end;
    font-size: 3rem;
}

.footer-links a:first-child {
    display: flex;
    align-items: center;
}

.footer-links img {
    width: 26px;
    height: 26px;
    margin: 5px;
}

.footer-links ul {
    display: flex;
    width: 40%;
    justify-self: flex-start;
    justify-content: space-between;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.5rem;
}

.copyright {
    display: flex;
    width: 100%;
    margin-bottom: 80px;
}

.copyright p {
    margin: 10px 120px;
    font-weight: 400;
    font-size: 1.12rem;
    line-height: 1.75rem;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
    .footer {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding-top: 64px;
    }

    .footer img {
        width: 84px;
        height: 73px;
    }

    .footer-inside {
        display: flex;
        width: 100%;
        padding: 10px 20px 10px 20px;
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap-reverse;
        justify-content: space-between;
        margin: 0;
    }

    .inside {
        padding: 10px 0px 80px 0px;
    }

    .inside a {
        text-align: center;
    }

    .footer-logo {
        display: flex;
        width: 100%;
        margin: 0px 0px 20px 0px;
        justify-content: flex-start;
    }

    .footer-links {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        height: auto;
        width: 100%;
        margin: 0;
        gap: 26px;
    }

    .footer-navlinks {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: auto;
        width: 100%;
        gap: 26px;
    }

    .footer-navlinks ul {
        padding: 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-between;
    }

    .footer-navlinks li {
        display: flex;
        width: 100%;
    }

    .footer-navlinks a {
        display: flex;
        width: 100%;
        margin: 20px 0px;
    }

    .footer-links a {
        display: flex;
        align-items: flex-end;
        font-size: 3rem;
    }

    .footer-links a:first-child {
        display: flex;
        align-items: center;
    }

    .footer-links img {
        width: 26.56px;
        height: 26.56px;
        margin: 5px;
    }

    .footer-links ul {
        display: flex;
        width: 40%;
        justify-self: flex-start;
        justify-content: space-between;
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.5rem;
    }

    .copyright {
        display: flex;
        width: 100%;
        margin-bottom: 80px;
    }

    .copyright p {
        margin: 10px 20px;
        font-weight: 400;
        font-size: 1.12rem;
        line-height: 1.75rem;
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .footer {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding-top: 64px;
    }

    .footer img {
        width: 84px;
        height: 73px;
    }

    .footer-inside {
        display: flex;
        width: 100%;
        padding: 10px 30px 10px 30px;
        flex-direction: initial;
        align-items: center;
        justify-content: space-between;
        margin: 0;
    }

    .inside {
        padding: 10px 0px 80px 0px;
    }

    .inside a {
        text-align: center;
    }

    .footer-logo {
        display: flex;
        width: 13%;
        margin: 0px 0px 0px 0px;
        justify-content: flex-start;
    }

    .footer-links {
        display: flex;
        align-items: flex-end;
        height: auto;
        width: 24%;
        margin: 0;
        gap: 26px;
    }

    .footer-navlinks {
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: auto;
        width: 50%;
    }

    .footer-navlinks ul {
        padding: 0;
        display: flex;
        width: 100%;
        margin: 0;
        justify-content: space-around;
    }

    .footer-navlinks a {
        display: flex;
        width: auto;
        margin: 0px;
        margin-right: 10px;
    }

    .footer-navlinks li {
        display: flex;
        width: auto;
        margin: 0;
        padding: 0;
    }

    .footer-links a {
        display: flex;
        align-items: flex-end;
        font-size: 3rem;
    }

    .footer-links a:first-child {
        display: flex;
        align-items: center;
    }

    .footer-links img {
        width: 26.56px;
        height: 26.56px;
        margin: 0;

    }

    .footer-links ul {
        display: flex;
        width: 40%;
        justify-self: flex-start;
        justify-content: space-between;
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.5rem;
    }

    .copyright {
        display: flex;
        width: 100%;
        margin-bottom: 80px;
    }

    .copyright p {
        margin: 10px 30px;
        font-weight: 400;
        font-size: 1.12rem;
        line-height: 1.75rem;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
    .footer {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding-top: 64px;
    }

    .footer img {
        width: 84px;
        height: 73px;
    }

    .footer-inside {
        display: flex;
        width: 100%;
        padding: 10px 30px 10px 30px;
        flex-direction: initial;
        align-items: center;
        justify-content: space-between;
        margin: 0;
    }

    .inside {
        padding: 10px 0px 80px 0px;
    }

    .inside a {
        text-align: center;
    }

    .footer-logo {
        display: flex;
        width: 13%;
        margin: 0px 0px 0px 0px;
        justify-content: flex-start;
    }

    .footer-links {
        display: flex;
        align-items: flex-end;
        height: auto;
        width: 24%;
        margin: 0;
        gap: 26px;
    }

    .footer-navlinks {
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: auto;
        width: 50%;
    }

    .footer-navlinks ul {
        padding: 0;
        display: flex;
        width: 100%;
        margin: 0;
        justify-content: space-around;
    }

    .footer-navlinks a {
        display: flex;
        width: auto;
        margin: 0px;
        margin-right: 10px;
    }

    .footer-navlinks li {
        display: flex;
        width: auto;
        margin: 0;
        padding: 0;
    }

    .footer-links a {
        display: flex;
        align-items: flex-end;
        font-size: 3rem;
    }

    .footer-links a:first-child {
        display: flex;
        align-items: center;
    }

    .footer-links img {
        width: 26.56px;
        height: 26.56px;
        margin: 0;

    }

    .footer-links ul {
        display: flex;
        width: 40%;
        justify-self: flex-start;
        justify-content: space-between;
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.5rem;
    }

    .copyright {
        display: flex;
        width: 100%;
        margin-bottom: 80px;
    }

    .copyright p {
        margin: 10px 30px;
        font-weight: 400;
        font-size: 1.12rem;
        line-height: 1.75rem;
    }
}

@media screen and (min-width: 1280px) and (max-width: 1439px) {

    .footer {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding-top: 64px;
    }

    .footer img {
        width: 84px;
        height: 73px;
    }

    .footer-inside {
        display: flex;
        width: 100%;
        padding: 10px 120px 80px 120px;
        align-items: center;
        justify-content: space-between;
    }

    .inside {
        padding: 10px 0px 80px 0px;
    }

    .inside a {
        text-align: center;
    }

    .footer-logo {
        display: flex;
        width: fit-content;
        justify-content: flex-start;
    }

    .footer-links {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        height: auto;
        width: fit-content;
        gap: 40px;
    }

    .footer-navlinks {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: auto;
        width: 40%;
        gap: 26px;
    }

    .footer-navlinks ul {
        padding: 0;
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    .footer-navlinks a {
        display: flex;
        width: auto;
    }

    .footer-navlinks li {
        display: flex;
        width: auto;
    }

    .footer-links a {
        display: flex;
        align-items: flex-end;
        font-size: 3rem;
    }

    .footer-links a:first-child {
        display: flex;
        align-items: center;
    }

    .footer-links img {
        width: 26.56px;
        height: 26.56px;
        margin: 5px;
    }

    .footer-links ul {
        display: flex;
        width: 40%;
        justify-self: flex-start;
        justify-content: space-between;
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.5rem;
    }

    .copyright {
        display: flex;
        width: 100%;
        margin-bottom: 80px;
    }

    .copyright p {
        margin: 10px 120px;
        font-weight: 400;
        font-size: 1.12rem;
        line-height: 1.75rem;
    }
}

@media screen and (min-width: 1440px) and (max-width: 1919px) {

    .footer {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding-top: 64px;
    }

    .footer img {
        width: 84px;
        height: 73px;
    }

    .footer-inside {
        display: flex;
        width: 100%;
        padding: 10px 120px 80px 120px;
        align-items: center;
        justify-content: space-between;
    }

    .inside {
        padding: 10px 0px 80px 0px;
    }

    .inside a {
        text-align: center;
    }

    .footer-logo {
        display: flex;
        width: fit-content;
        justify-content: flex-start;
    }

    .footer-links {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        height: auto;
        width: fit-content;
        gap: 40px;
    }

    .footer-navlinks {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: auto;
        width: 40%;
        gap: 26px;
    }

    .footer-navlinks ul {
        padding: 0;
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    .footer-navlinks a {
        display: flex;
        width: auto;
    }

    .footer-navlinks li {
        display: flex;
        width: auto;
    }

    .footer-links a {
        display: flex;
        align-items: flex-end;
        font-size: 3rem;
    }

    .footer-links a:first-child {
        display: flex;
        align-items: center;
    }

    .footer-links img {
        width: 26px;
        height: 26px;
        margin: 5px;
    }

    .footer-links ul {
        display: flex;
        width: 40%;
        justify-self: flex-start;
        justify-content: space-between;
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.5rem;
    }

    .copyright {
        display: flex;
        width: 100%;
        margin-bottom: 80px;
    }

    .copyright p {
        margin: 10px 120px;
        font-weight: 400;
        font-size: 1.12rem;
        line-height: 1.75rem;
    }
}

@media screen and (min-width: 1920px) {

    .footer {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding-top: 64px;
    }

    .footer img {
        width: 84px;
        height: 73px;
    }

    .footer-inside {
        display: flex;
        width: 100%;
        padding: 10px 120px 80px 120px;
        align-items: center;
        justify-content: space-between;
    }

    .inside {
        padding: 10px 0px 80px 0px;
    }

    .inside a {
        text-align: center;
    }

    .footer-logo {
        display: flex;
        width: fit-content;
        justify-content: flex-start;
    }

    .footer-links {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        height: auto;
        width: fit-content;
        gap: 40px;
    }

    .footer-navlinks {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: auto;
        width: 40%;
        gap: 26px;
    }

    .footer-navlinks ul {
        padding: 0;
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    .footer-navlinks a {
        display: flex;
        width: auto;
    }

    .footer-navlinks li {
        display: flex;
        width: auto;
    }

    .footer-links a {
        display: flex;
        align-items: flex-end;
        font-size: 3rem;
    }

    .footer-links a:first-child {
        display: flex;
        align-items: center;
    }

    .footer-links img {
        width: 26px;
        height: 26px;
        margin: 5px;
    }

    .footer-links ul {
        display: flex;
        width: 40%;
        justify-self: flex-start;
        justify-content: space-between;
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.5rem;
    }

    .copyright {
        display: flex;
        width: 100%;
        margin-bottom: 80px;
    }

    .copyright p {
        margin: 10px 120px;
        font-weight: 400;
        font-size: 1.12rem;
        line-height: 1.75rem;
    }
}