.section-five {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 80px 0px;
    background-color: #003059;
    color: white;
}

.devider {
    fill: #f3f1ef !important;
}

.section-five h3 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 60px 0px 20px 0px;
    color: white;
}

.anim {
    position: absolute;
}

.section-five h2 {
    color: white;
    display: flex;
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 5rem;
    line-height: 6rem;
    margin: 0;
    margin: 0px 0px 7rem 0px;
}

.section-five_inside {
    display: flex;
    width: 80%;
    margin: 60px auto;
    justify-content: space-between;
}

.section-five_inside-block {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.section-five_inside-contacts {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.section-five_inside-contacts-left {
    display: flex;
    width: 30%;
    flex-direction: column;
}

.section-five {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 80px 0px;
    background-color: #003059;
    color: white;
}

.devider {
    fill: #f3f1ef !important;
}

.section-five h3 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 60px 0px 20px 0px;
    color: white;
}

.anim {
    position: absolute;
}

.section-five h2 {
    color: white;
    display: flex;
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 4rem;
    line-height: 4.4rem;
    margin: 0;
    margin: 0px 0px 7rem 0px;
}

.section-five_inside {
    display: flex;
    width: 80%;
    margin: 60px auto;
    justify-content: space-between;
}

.section-five_inside-block {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.section-five_inside-contacts {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.section-five_inside-contacts-left {
    display: flex;
    width: 30%;
    flex-direction: column;
}

.section-five_inside-contacts-left p a {
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1.75rem;
    color: white;
}

.section-five_inside-contacts-left a {
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 2.5rem;
    padding-bottom: 8px;
    color: white;
}

.section-five_inside-contacts-left span {
    font-weight: 400;
    display: flex;
    width: 90%;
    font-size: 1rem;
    color: #9f9f9f;
    line-height: 1.5rem;
    color: white;
}

.section-five_inside-contacts-center {
    display: flex;
    margin-top: 20px;
    cursor: pointer;
    flex-direction: column;
    width: 30%;
}

.section-five_inside-contacts-right {
    display: flex;
    margin-top: 20px;
    cursor: pointer;
    flex-direction: column;
    width: 30%;
}

.section-five_inside-contacts-center span {
    width: 80%;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.5rem;
    text-transform: uppercase;
    padding: 20px 20px 20px 0px;
    border-bottom: 1.5px solid white;
}

.border-line {
    margin-top: 18px;
    width: 100%;
    border-bottom: 1px solid white;
}

.section-five_inside-contacts-right span {
    width: 80%;
    font-weight: 700;
    font-size: 1.5rem;
    padding: 20px 20px 20px 0px;
    border-bottom: 1.5px solid white;
    line-height: 1.5rem;
    text-transform: uppercase;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
    .section-five_inside {
        display: flex;
        width: 100%;
        margin: 80px 16px;
        justify-content: space-between;
    }
.section-five_inside-block h3{
    text-align: center;
    padding-top: 1em;
}
    .section-five_inside-contacts {
        flex-direction: column;
        align-items: flex-start;
    }

    .section-five_inside-contacts-left {
        width: 100%;
    }

    .section-five_inside-contacts-center {
        width: 60%;
    }

    .section-five_inside-contacts-right {
        width: 60%;
    }

    .section-five {
        padding: 10px 0px;
    }

}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .section-five {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding: 80px 0px;
        background-color: #003059;
        color: white;
    }

    .devider {
        fill: #f3f1ef !important;
    }

    .section-five h3 {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1.2rem;
        line-height: 2rem;
        margin: 60px 0px 20px 0px;
        color: white;
    }

    .anim {
        position: absolute;
    }

    .section-five h2 {
        color: white;
        display: flex;
        width: 100%;
        text-align: center;
        font-weight: 500;
        font-size: 2.8rem;
        line-height: 3.5rem;
        margin: 0;
        margin: 0px 0px 7rem 0px;
    }

    .section-five_inside {
        display: flex;
        width: 80%;
        margin: 60px auto;
        justify-content: space-between;
    }

    .section-five_inside-block {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    .section-five_inside-contacts {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }

    .section-five_inside-contacts-left {
        display: flex;
        width: 30%;
        flex-direction: column;
    }

    .section-five_inside-contacts-left p a {

        font-weight: 500;
        font-size: 1.2rem;
        line-height: 1.5rem;
        color: white;
    }

    .section-five_inside-contacts-left a {
        display: flex;
        width: 100%;
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.5rem;
        padding-bottom: 8px;
        color: white;
    }

    .section-five_inside-contacts-left span {
        font-weight: 400;
        display: flex;
        width: 100%;
        font-size: 0.9rem;
        color: #9f9f9f;
        line-height: 1.5rem;

    }

    .section-five_inside-contacts-center {
        display: flex;
        margin-top: 20px;
        cursor: pointer;
        flex-direction: column;
        width: 30%;
    }

    .section-five_inside-contacts-right {
        display: flex;
        margin-top: 20px;
        cursor: pointer;
        flex-direction: column;
        width: 30%;
    }

    .section-five_inside-contacts-center span {
        width: 100%;
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.5rem;
        text-transform: uppercase;
        padding: 20px 20px 10px 0px;
        border-bottom: 1.5px solid white;
    }

    .border-line {
        margin-top: 18px;
        width: 100%;
        border-bottom: 1px solid white;
    }

    .section-five_inside-contacts-right span {
        width: 100%;
        font-weight: 700;
        font-size: 1rem;
        padding: 20px 20px 10px 0px;
        border-bottom: 1.5px solid white;
        line-height: 1.5rem;
        text-transform: uppercase;
    }


}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
    .section-five {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding: 80px 0px;
        background-color: #003059;
        color: white;
    }

    .devider {
        fill: #f3f1ef !important;
    }

    .section-five h3 {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1.4rem;
        line-height: 2rem;
        margin: 60px 0px 20px 0px;
        color: white;
    }

    .anim {
        position: absolute;
    }

    .section-five h2 {
        color: white;
        display: flex;
        width: 100%;
        text-align: center;
        font-weight: 500;
        font-size: 3.2rem;
        line-height: 4rem;
        margin: 0;
        margin: 0px 0px 7rem 0px;
    }

    .section-five_inside {
        display: flex;
        width: 80%;
        margin: 60px auto;
        justify-content: space-between;
    }

    .section-five_inside-block {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    .section-five_inside-contacts {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }

    .section-five_inside-contacts-left {
        display: flex;
        width: 30%;
        flex-direction: column;
    }

    .section-five_inside-contacts-left p a {
        font-weight: 500;
        font-size: 1.3rem;
        line-height: 1.5rem;
        color: white;
    }

    .section-five_inside-contacts-left a {
        font-weight: 700;
        font-size: 1.3rem;
        line-height: 1.5rem;
        padding-bottom: 8px;
        color: white;
    }

    .section-five_inside-contacts-left span {
        font-weight: 400;
        display: flex;
        width: 100%;
        font-size: 1rem;
        color: #9f9f9f;
        line-height: 1.5rem;
    }

    .section-five_inside-contacts-center {
        display: flex;
        margin-top: 20px;
        cursor: pointer;
        flex-direction: column;
        width: 30%;
    }

    .section-five_inside-contacts-right {
        display: flex;
        margin-top: 20px;
        cursor: pointer;
        flex-direction: column;
        width: 30%;
    }

    .section-five_inside-contacts-center span {
        width: 100%;
        font-weight: 700;
        font-size: 1.3rem;
        line-height: 1.5rem;
        text-transform: uppercase;
        padding: 20px 20px 20px 0px;
        border-bottom: 1.5px solid white;
    }

    .border-line {
        margin-top: 18px;
        width: 100%;
        border-bottom: 1px solid white;
    }

    .section-five_inside-contacts-right span {
        width: 100%;
        font-weight: 700;
        font-size: 1.3rem;
        padding: 20px 20px 20px 0px;
        border-bottom: 1.5px solid white;
        line-height: 1.5rem;
        text-transform: uppercase;
    }

}

@media screen and (min-width: 1280px) and (max-width: 1439px) {
    .section-five {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding: 80px 0px;
        background-color: #003059;
        color: white;
    }

    .devider {
        fill: #f3f1ef !important;
    }

    .section-five h3 {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 2rem;
        margin: 60px 0px 20px 0px;
        color: white;
    }

    .anim {
        position: absolute;
    }

    .section-five h2 {
        color: white;
        display: flex;
        width: 100%;
        text-align: center;
        font-weight: 500;
        font-size: 4rem;
        line-height: 4.4rem;
        margin: 0;
        margin: 0px 0px 7rem 0px;
    }

    .section-five_inside {
        display: flex;
        width: 80%;
        margin: 60px auto;
        justify-content: space-between;
    }

    .section-five_inside-block {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    .section-five_inside-contacts {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }

    .section-five_inside-contacts-left {
        display: flex;
        width: 30%;
        flex-direction: column;
    }

    .section-five_inside-contacts-left p a {
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 1.75rem;
        color: white;
    }

    .section-five_inside-contacts-left a {
        font-weight: 700;
        font-size: 1.8rem;
        line-height: 2.5rem;
        padding-bottom: 8px;
        color: white;
    }

    .section-five_inside-contacts-left span {
        font-weight: 400;
        display: flex;
        width: 90%;
        font-size: 1rem;
        color: #9f9f9f;
        line-height: 1.5rem;
        color: white;
    }

    .section-five_inside-contacts-center {
        display: flex;
        margin-top: 20px;
        cursor: pointer;
        flex-direction: column;
        width: 30%;
    }

    .section-five_inside-contacts-right {
        display: flex;
        margin-top: 20px;
        cursor: pointer;
        flex-direction: column;
        width: 30%;
    }

    .section-five_inside-contacts-center span {
        width: 90%;
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 1.5rem;
        text-transform: uppercase;
        padding: 20px 20px 20px 0px;
        border-bottom: 1.5px solid white;
    }

    .border-line {
        margin-top: 18px;
        width: 100%;
        border-bottom: 1px solid white;
    }

    .section-five_inside-contacts-right span {
        width: 90%;
        font-weight: 700;
        font-size: 1.5rem;
        padding: 20px 20px 20px 0px;
        border-bottom: 1.5px solid white;
        line-height: 1.5rem;
        text-transform: uppercase;
    }

}

@media screen and (min-width: 1440px) and (max-width: 1919px) {
    .section-five {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding: 80px 0px;
        background-color: #003059;
        color: white;
    }

    .devider {
        fill: #f3f1ef !important;
    }

    .section-five h3 {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 2rem;
        margin: 60px 0px 20px 0px;
        color: white;
    }

    .anim {
        position: absolute;
    }

    .section-five h2 {
        color: white;
        display: flex;
        width: 100%;
        text-align: center;
        font-weight: 500;
        font-size: 4rem;
        line-height: 4.4rem;
        margin: 0;
        margin: 0px 0px 7rem 0px;
    }

    .section-five_inside {
        display: flex;
        width: 80%;
        margin: 60px auto;
        justify-content: space-between;
    }

    .section-five_inside-block {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    .section-five_inside-contacts {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }

    .section-five_inside-contacts-left {
        display: flex;
        width: 30%;
        flex-direction: column;
    }

    .section-five_inside-contacts-left p a {
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 1.75rem;
        color: white;
    }

    .section-five_inside-contacts-left a {
        font-weight: 700;
        font-size: 1.8rem;
        line-height: 2.5rem;
        padding-bottom: 8px;
        color: white;
    }

    .section-five_inside-contacts-left span {
        font-weight: 400;
        display: flex;
        width: 90%;
        font-size: 1rem;
        color: #9f9f9f;
        line-height: 1.5rem;
        color: white;
    }

    .section-five_inside-contacts-center {
        display: flex;
        margin-top: 20px;
        cursor: pointer;
        flex-direction: column;
        width: 30%;
    }

    .section-five_inside-contacts-right {
        display: flex;
        margin-top: 20px;
        cursor: pointer;
        flex-direction: column;
        width: 30%;
    }

    .section-five_inside-contacts-center span {
        width: 80%;
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 1.5rem;
        text-transform: uppercase;
        padding: 20px 20px 20px 0px;
        border-bottom: 1.5px solid white;
    }

    .border-line {
        margin-top: 18px;
        width: 100%;
        border-bottom: 1px solid white;
    }

    .section-five_inside-contacts-right span {
        width: 80%;
        font-weight: 700;
        font-size: 1.5rem;
        padding: 20px 20px 20px 0px;
        border-bottom: 1.5px solid white;
        line-height: 1.5rem;
        text-transform: uppercase;
    }
}

@media screen and (min-width: 1920px) {
    .section-five {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding: 80px 0px;
        background-color: #003059;
        color: white;
    }

    .devider {
        fill: #f3f1ef !important;
    }

    .section-five h3 {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 2rem;
        margin: 60px 0px 20px 0px;
        color: white;
    }

    .anim {
        position: absolute;
    }

    .section-five h2 {
        color: white;
        display: flex;
        width: 80%;
        text-align: center;
        font-weight: 500;
        font-size: 4rem;
        line-height: 4.4rem;
        margin: 0;
        margin: 1em 0px 1em 0px;
    }

    .section-five_inside {
        display: flex;
        width: 80%;
        margin: 60px auto;
        justify-content: space-between;
    }

    .section-five_inside-block {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    .section-five_inside-contacts {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }

    .section-five_inside-contacts-left {
        display: flex;
        width: 30%;
        flex-direction: column;
    }

    .section-five_inside-contacts-left p a {
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 1.75rem;
        color: white;
    }

    .section-five_inside-contacts-left a {
        font-weight: 700;
        font-size: 1.8rem;
        line-height: 2.5rem;
        padding-bottom: 8px;
        color: white;
    }

    .section-five_inside-contacts-left span {
        font-weight: 400;
        display: flex;
        width: 90%;
        font-size: 1rem;
        color: #9f9f9f;
        line-height: 1.5rem;
        color: white;
    }

    .section-five_inside-contacts-center {
        display: flex;
        margin-top: 20px;
        cursor: pointer;
        flex-direction: column;
        width: 30%;
    }

    .section-five_inside-contacts-right {
        display: flex;
        margin-top: 20px;
        cursor: pointer;
        flex-direction: column;
        width: 30%;
    }

    .section-five_inside-contacts-center span {
        width: 80%;
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 1.5rem;
        text-transform: uppercase;
        padding: 20px 20px 20px 0px;
        border-bottom: 1.5px solid white;
    }

    .border-line {
        margin-top: 18px;
        width: 100%;
        border-bottom: 1px solid white;
    }

    .section-five_inside-contacts-right span {
        width: 80%;
        font-weight: 700;
        font-size: 1.5rem;
        padding: 20px 20px 20px 0px;
        border-bottom: 1.5px solid white;
        line-height: 1.5rem;
        text-transform: uppercase;
    }
}