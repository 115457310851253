.custom-shape-divider-top-1676300708 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-top-1676300708 svg {
    position: relative;
    display: block;
    width: calc(144% + 1.3px);
    height: 144px;
}

.two {
    background-color: #003059 !important;
    padding-bottom: 140px;
    z-index: 3;
}

.two .text {
    color: white;
}

.section-two {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    background-color: #fffdfe;
    margin: 0;
}

.realitive {
    position: relative;
    transform-style: inherit;
    z-index: 0;
}

.realitive:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}


.gap {
    margin-top: 10rem !important;
}

.section-two h2 {
    display: flex;
    width: 60%;
    text-align: center;
    justify-content: center;
    font-weight: 800;
    font-size: 3.4rem;
    line-height: 4rem;
    margin: 0px 0px 80px 0px;
}

.section-two h3 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2rem;
    margin: 60px 0px 20px 0px;
}

.section-two h5 {
    display: flex;
    width: 90%;
    text-align: center;
    justify-content: center;
    font-weight: 800;
    font-size: 3.5rem;
    line-height: 4.5rem;
    margin: 0px 0px 60px 0px;
}

.section-two_inside {
    display: flex;
    width: 100%;
    margin: 180px 120px 0px 120px;
    justify-content: space-between;
}

.section-two_inside-block {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.section-two_inside-images {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 5rem;
}

.section-two_inside-images img {
    width: 250px;
    height: 250px;
}

.section-two_inside-block p {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2rem;
}

.itc-p {
    display: flex;
    width: 85%;
    text-align: justify;
    text-align-last: center;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.7rem;
}

.section-two_inside-images-left {
    width: 25%;
    text-align: center;
}

.section-two_inside-images-center {
    width: 25%;
    text-align: center;
}

.section-two_inside-images-right {
    width: 24%;
    text-align: center;
}


@media screen and (min-width: 320px) and (max-width: 767px) {
    .custom-shape-divider-top-1676300708 {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
        transform: rotate(180deg);
    }

    .custom-shape-divider-top-1676300708 svg {
        position: relative;
        display: block;
        width: calc(144% + 1.3px);
        height: 144px;
    }


    .two {
        background-color: #003059 !important;
        padding-bottom: 140px;
        z-index: 3;
    }

    .two .text {
        font-size: 1.5em;
        line-height: 2em;
        color: white;
    }


    .section-two_inside {
        display: flex;
        width: 100%;
        margin: 30px 16px;
        justify-content: space-between;
    }

    .realitive {
        position: relative;
        transform-style: inherit;
        z-index: 0;
    }

    .realitive:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .section-two h3 {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
        text-align: center;
        color: #121212;
    }

    .section-two h5 {
        font-size: 1.8rem;
        line-height: 2rem;
        font-style: normal;
        font-weight: 800;
        text-align: center;
        color: #121212;
    }

    .gap {
        margin-top: 10rem !important;
    }

    .section-two h2 {
        width: 100%;
        font-style: normal;
        font-weight: 700;
        font-size: 42px;
        line-height: 56px;
        text-align: center;
        color: #121212;
    }


    .section-two_inside-block {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    .section-two_inside-images {
        flex-wrap: wrap;
        margin-bottom: 5rem;
    }

    .section-two_inside-images img {
        width: 250px;
        height: 250px;
    }

    .section-two_inside-block p {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        text-align: center;
        color: #121212;
    }

    .itc-p {
        display: flex;
        width: 85%;
        text-align: justify;
        text-align-last: center;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.7rem;
    }

    .section-two h5 {
        display: flex;
        width: 90%;
        text-align: center;
        justify-content: center;
        font-weight: 800;
        font-size: 3.5rem;
        line-height: 4.5rem;
        margin: 0px 0px 60px 0px;
    }

    .section-two_inside-images-left {
        width: 100%;
        text-align: center;
    }

    .section-two_inside-images-center {
        width: 100%;
        text-align: center;
    }

    .section-two_inside-images-right {
        width: 100%;
        text-align: center;
    }

}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .custom-shape-divider-top-1676300708 {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
        transform: rotate(180deg);
    }

    .custom-shape-divider-top-1676300708 svg {
        position: relative;
        display: block;
        width: calc(144% + 1.3px);
        height: 144px;
    }

    .two {
        background-color: #003059 !important;
        padding-bottom: 140px;
        z-index: 3;
    }

    .two .text {
        color: white;
    }

    .section-two {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        background-color: #fffdfe;
        margin: 0;
    }

    .realitive {
        position: relative;
        transform-style: inherit;
        z-index: 0;
    }

    .realitive:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .section-two h3 {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1rem;
        line-height: 2rem;
        margin: 60px 0px 20px 0px;
    }

    .gap {
        margin-top: 10rem !important;
    }

    .section-two h2 {
        display: flex;
        width: 60%;
        text-align: center;
        justify-content: center;
        font-weight: 800;
        font-size: 2.8rem;
        line-height: 3.5rem;
        margin: 0px 0px 80px 0px;
    }

    .section-two_inside {
        display: flex;
        width: 100%;
        margin: 30px 60px;
        justify-content: space-between;
    }

    .section-two_inside-block {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    .section-two_inside-block p {
        font-weight: 400;
        font-size: 0.9rem;
        line-height: 1.7rem;
    }

    .section-two_inside-images {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 5rem;
    }

    .section-two_inside-images img {
        width: 250px;
        height: 250px;
    }


    .itc-p {
        display: flex;
        width: 100%;
        text-align: justify;
        text-align-last: center;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.7rem;
    }

    .section-two h5 {
        font-size: 2.8rem;
        line-height: 3.5rem;
    }

    .section-two_inside-images-left img {
        width: 98px;
        height: 98px;
    }

    .section-two_inside-images-center img {
        width: 98px;
        height: 98px;
    }

    .section-two_inside-images-right img {
        width: 98px;
        height: 98px;
    }

}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
    .custom-shape-divider-top-1676300708 {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
        transform: rotate(180deg);
    }

    .custom-shape-divider-top-1676300708 svg {
        position: relative;
        display: block;
        width: calc(144% + 1.3px);
        height: 144px;
    }

    .two {
        background-color: #003059 !important;
        padding-bottom: 140px;
        z-index: 3;
    }

    .two .text {
        color: white;
    }

    .section-two {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        background-color: #fffdfe;
        margin: 0;
    }

    .realitive {
        position: relative;
        transform-style: inherit;
        z-index: 0;
    }

    .realitive:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }


    .gap {
        margin-top: 10rem !important;
    }

    .section-two h2 {
        display: flex;
        width: 60%;
        text-align: center;
        justify-content: center;
        font-weight: 800;
        font-size: 3rem;
        line-height: 4rem;
        margin: 0px 0px 80px 0px;
    }

    .section-two h3 {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1.4rem;
        line-height: 2rem;
        margin: 60px 0px 20px 0px;
    }

    .section-two h5 {
        display: flex;
        width: 90%;
        text-align: center;
        justify-content: center;
        font-weight: 800;
        font-size: 3rem;
        line-height: 4rem;
        margin: 0px 0px 60px 0px;
    }

    .section-two_inside {
        display: flex;
        width: 100%;
        margin: 180px 120px 0px 120px;
        justify-content: space-between;
    }

    .section-two_inside-block {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    .section-two_inside-images {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 5rem;
    }

    .section-two_inside-images img {
        width: 250px;
        height: 250px;
    }

    .section-two_inside-block p {
        font-weight: 400;
        font-size: 1.1rem;
        line-height: 1.7rem;
    }

    .itc-p {
        display: flex;
        width: 100%;
        text-align: justify;
        text-align-last: center;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.7rem;
    }

    .section-two_inside-images-left {
        width: 25%;
        text-align: center;
    }

    .section-two_inside-images-center {
        width: 25%;
        text-align: center;
    }

    .section-two_inside-images-right {
        width: 24%;
        text-align: center;
    }

}

@media screen and (min-width: 1280px) and (max-width: 1439px) {
    .custom-shape-divider-top-1676300708 {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
        transform: rotate(180deg);
    }

    .custom-shape-divider-top-1676300708 svg {
        position: relative;
        display: block;
        width: calc(144% + 1.3px);
        height: 144px;
    }

    .two {
        background-color: #003059 !important;
        padding-bottom: 140px;
        z-index: 3;
    }

    .two .text {
        color: white;
    }

    .section-two {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        background-color: #fffdfe;
        margin: 0;
    }

    .realitive {
        position: relative;
        transform-style: inherit;
        z-index: 0;
    }

    .realitive:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }


    .gap {
        margin-top: 10rem !important;
    }

    .section-two h2 {
        display: flex;
        width: 60%;
        text-align: center;
        justify-content: center;
        font-weight: 800;
        font-size: 3.5rem;
        line-height: 5rem;
        margin: 0px 0px 80px 0px;
    }

    .section-two h3 {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 2rem;
        margin: 60px 0px 20px 0px;
    }

    .section-two h5 {
        display: flex;
        width: 90%;
        text-align: center;
        justify-content: center;
        font-weight: 800;
        font-size: 3.5rem;
        line-height: 4rem;
        margin: 0px 0px 60px 0px;
    }

    .section-two_inside {
        display: flex;
        width: 100%;
        margin: 180px 120px 0px 120px;
        justify-content: space-between;
    }

    .section-two_inside-block {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    .section-two_inside-images {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 5rem;
    }

    .section-two_inside-images img {
        width: 250px;
        height: 250px;
    }

    .section-two_inside-block p {
        font-weight: 400;
        font-size: 1.3rem;
        line-height: 1.7rem;
    }

    .itc-p {
        display: flex;
        width: 100%;
        text-align: justify;
        text-align-last: center;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.7rem;
    }

    .section-two_inside-images-left {
        width: 33%;
        text-align: center;
    }

    .section-two_inside-images-center {
        width: 35%;
        text-align: center;
    }

    .section-two_inside-images-right {
        width: 30%;
        text-align: center;
    }
}

@media screen and (min-width: 1440px) and (max-width: 1919px) {
    .custom-shape-divider-top-1676300708 {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
        transform: rotate(180deg);
    }

    .custom-shape-divider-top-1676300708 svg {
        position: relative;
        display: block;
        width: calc(144% + 1.3px);
        height: 144px;
    }

    .two {
        background-color: #003059 !important;
        padding-bottom: 140px;
        z-index: 3;
    }

    .two .text {
        color: white;
    }

    .section-two {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        background-color: #fffdfe;
        margin: 0;
    }

    .realitive {
        position: relative;
        transform-style: inherit;
        z-index: 0;
    }

    .realitive:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }


    .gap {
        margin-top: 10rem !important;
    }

    .section-two h2 {
        display: flex;
        width: 60%;
        text-align: center;
        justify-content: center;
        font-weight: 800;
        font-size: 3.5rem;
        line-height: 5rem;
        margin: 0px 0px 80px 0px;
    }

    .section-two h3 {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 2rem;
        margin: 60px 0px 20px 0px;
    }

    .section-two h5 {
        display: flex;
        width: 90%;
        text-align: center;
        justify-content: center;
        font-weight: 800;
        font-size: 3.5rem;
        line-height: 4rem;
        margin: 0px 0px 60px 0px;
    }

    .section-two_inside {
        display: flex;
        width: 100%;
        margin: 180px 120px 0px 120px;
        justify-content: space-between;
    }

    .section-two_inside-block {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    .section-two_inside-images {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 5rem;
    }

    .section-two_inside-images img {
        width: 250px;
        height: 250px;
    }

    .section-two_inside-block p {
        font-weight: 400;
        font-size: 1.3rem;
        line-height: 1.7rem;
    }

    .itc-p {
        display: flex;
        width: 100%;
        text-align: justify;
        text-align-last: center;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.7rem;
    }

    .section-two_inside-images-left {
        width: 33%;
        text-align: center;
    }

    .section-two_inside-images-center {
        width: 35%;
        text-align: center;
    }

    .section-two_inside-images-right {
        width: 30%;
        text-align: center;
    }
}

@media screen and (min-width: 1920px) {
    .custom-shape-divider-top-1676300708 {
        position: absolute;
        top: -2px;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
        transform: rotate(180deg);
    }

    .custom-shape-divider-top-1676300708 svg {
        position: relative;
        display: block;
        width: calc(144% + 1.3px);
        height: 144px;
    }

    .two {
        background-color: #003059 !important;
        padding-bottom: 140px;
        z-index: 3;
    }

    .two .text {
        color: white;
    }

    .section-two {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        background-color: #fffdfe;
        margin: 0;
    }

    .realitive {
        position: relative;
        transform-style: inherit;
        z-index: 0;
    }

    .realitive:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .gap {
        margin-top: 10rem !important;
    }

    .section-two h2 {
        display: flex;
        width: 60%;
        text-align: center;
        justify-content: center;
        font-weight: 800;
        font-size: 3.5rem;
        line-height: 5rem;
        margin: 0px 0px 80px 0px;
    }

    .section-two h3 {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 2rem;
        margin: 60px 0px 20px 0px;
    }

    .section-two h5 {
        display: flex;
        width: 90%;
        text-align: center;
        justify-content: center;
        font-weight: 800;
        font-size: 3.5rem;
        line-height: 4rem;
        margin: 0px 0px 60px 0px;
    }

    .section-two_inside {
        display: flex;
        width: 100%;
        margin: 180px 120px 0px 120px;
        justify-content: space-between;
    }

    .section-two_inside-block {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    .section-two_inside-images {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 5rem;
    }

    .section-two_inside-images img {
        width: 250px;
        height: 250px;
    }

    .section-two_inside-block p {
        font-weight: 400;
        font-size: 1.3rem;
        line-height: 1.7rem;
    }

    .itc-p {
        display: flex;
        width: 100%;
        text-align: justify;
        text-align-last: center;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.7rem;
    }

    .section-two_inside-images-left {
        width: 33%;
        text-align: center;
    }

    .section-two_inside-images-center {
        width: 35%;
        text-align: center;
    }

    .section-two_inside-images-right {
        width: 30%;
        text-align: center;
    }
}
