.section-four {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    background-color: #f3f1ef;
    overflow-x: hidden;
    margin: 0;
    padding: 30px 0px 60px 0px;
}

.section-four_inside {
    display: flex;
    width: 100%;
    margin: 30px auto;
    justify-content: space-between;
}

.section-four_inside-block {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.section-four_inside-block h3 {
    font-weight: 700;
    font-size: 2.1rem;
    line-height: 2rem;
    text-transform: uppercase;
    margin-right: 0;
    margin-bottom: 60px;
}

.section-four_inside-block-map {
    display: flex;
    width: 100%;
    /* flex-wrap: wrap; */
    flex-direction: row;
    white-space: nowrap;
    justify-content: space-between;

}

.section-box {
    box-sizing: border-box;;
    width: auto;
    display: flex;
    margin: 20px;
    align-items: center;
    height: auto;
}


@media screen and (min-width: 320px) and (max-width: 767px) {
    .section-four {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        background-color: #f3f1ef;
        overflow-x: hidden;
        margin: 0;
        padding: 30px 0px 60px 0px;
    }

    .section-four_inside {
        display: flex;
        width: 100%;
        margin: 30px;
        justify-content: space-between;
    }

    .section-four_inside-block {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    .section-four_inside-block h3 {
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 2rem;
        text-transform: uppercase;
        margin-right: 10%;
        margin-bottom: 60px;
    }

    .section-four_inside-block-map {
        display: flex;
        width: 100%;
        /* flex-wrap: wrap; */
        flex-direction: row;
        white-space: nowrap;
        justify-content: space-between;

    }

    .section-box {
        box-sizing: border-box;;
        width: auto;
        display: flex;
        margin: 20px;
        align-items: center;
        height: auto;
    }

}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .section-four {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        background-color: #f3f1ef;
        overflow-x: hidden;
        margin: 0;
        padding: 30px 0px 60px 0px;
    }

    .section-four_inside {
        display: flex;
        width: 100%;
        margin: 30px auto;
        justify-content: space-between;
    }

    .section-four_inside-block {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    .section-four_inside-block h3 {
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 2rem;
        text-transform: uppercase;
        margin-right: 0;
        margin-bottom: 60px;
    }

    .section-four_inside-block-map {
        display: flex;
        width: 100%;
        /* flex-wrap: wrap; */
        flex-direction: row;
        white-space: nowrap;
        justify-content: space-between;

    }

    .section-box {
        box-sizing: border-box;;
        width: auto;
        display: flex;
        margin: 20px;
        align-items: center;
        height: auto;
    }

}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
    .section-four {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        background-color: #f3f1ef;
        overflow-x: hidden;
        margin: 0;
        padding: 30px 0px 60px 0px;
    }

    .section-four_inside {
        display: flex;
        width: 100%;
        margin: 30px auto;
        justify-content: space-between;
    }

    .section-four_inside-block {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    .section-four_inside-block h3 {
        font-weight: 700;
        font-size: 1.9rem;
        line-height: 2rem;
        text-transform: uppercase;
        margin-right: 0;
        margin-bottom: 60px;
    }

    .section-four_inside-block-map {
        display: flex;
        width: 100%;
        /* flex-wrap: wrap; */
        flex-direction: row;
        white-space: nowrap;
        justify-content: space-between;

    }

    .section-box {
        box-sizing: border-box;;
        width: auto;
        display: flex;
        margin: 20px;
        align-items: center;
        height: auto;
    }
}

@media screen and (min-width: 1280px) and (max-width: 1439px) {
    .section-four {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        background-color: #f3f1ef;
        overflow-x: hidden;
        margin: 0;
        padding: 30px 0px 60px 0px;
    }

    .section-four_inside {
        display: flex;
        width: 100%;
        margin: 30px auto;
        justify-content: space-between;
    }

    .section-four_inside-block {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    .section-four_inside-block h3 {
        font-weight: 700;
        font-size: 2rem;
        line-height: 2rem;
        text-transform: uppercase;
        margin-right: 0;
        margin-bottom: 60px;
    }

    .section-four_inside-block-map {
        display: flex;
        width: 100%;
        /* flex-wrap: wrap; */
        flex-direction: row;
        white-space: nowrap;
        justify-content: space-between;

    }

    .section-box {
        box-sizing: border-box;;
        width: auto;
        display: flex;
        margin: 20px;
        align-items: center;
        height: auto;
    }

}

@media screen and (min-width: 1440px) and (max-width: 1919px) {
    .section-four {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        background-color: #f3f1ef;
        overflow-x: hidden;
        margin: 0;
        padding: 30px 0px 60px 0px;
    }

    .section-four_inside {
        display: flex;
        width: 100%;
        margin: 30px auto;
        justify-content: space-between;
    }

    .section-four_inside-block {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    .section-four_inside-block h3 {
        font-weight: 700;
        font-size: 2.1rem;
        line-height: 2rem;
        text-transform: uppercase;
        margin-right: 0;
        margin-bottom: 60px;
    }

    .section-four_inside-block-map {
        display: flex;
        width: 100%;
        /* flex-wrap: wrap; */
        flex-direction: row;
        white-space: nowrap;
        justify-content: space-between;

    }

    .section-box {
        box-sizing: border-box;;
        width: auto;
        display: flex;
        margin: 20px;
        align-items: center;
        height: auto;
    }

}

@media screen and (min-width: 1920px) {
    .section-four {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        background-color: #f3f1ef;
        overflow-x: hidden;
        margin: 0;
        padding: 30px 0px 60px 0px;
    }

    .section-four_inside {
        display: flex;
        width: 100%;
        margin: 30px auto;
        justify-content: space-between;
    }

    .section-four_inside-block {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    .section-four_inside-block h3 {
        font-weight: 700;
        font-size: 2.3rem;
        line-height: 2rem;
        text-transform: uppercase;
        margin-right: 0;
        margin-bottom: 60px;
    }

    .section-four_inside-block-map {
        display: flex;
        width: 100%;
        /* flex-wrap: wrap; */
        flex-direction: row;
        white-space: nowrap;
        justify-content: space-between;

    }

    .section-box {
        box-sizing: border-box;;
        width: auto;
        display: flex;
        margin: 50px;
        align-items: center;
        height: auto;
    }

}